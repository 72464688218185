<template>
  <!-- width="29" height="21"  -->
  <path
    d="M1.99976 13.0188L7.62822 13.0188V19.4949H1.99976L1.99976 13.0188Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M12.1711 1.99994L27.1233 1.99994L27.1233 8.47601H12.1711L12.1711 1.99994Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M12.1711 13.0188L27.1233 13.0188L27.1233 19.4949H12.1711L12.1711 13.0188Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M1.84741 4.81408L4.31318 7.7807L8.62827 1.84745"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
  />
</template>
