<template>
  <!-- w-28 h-26 -->
  <path
    d="M1 8.75309L13.5617 1.16816L26.1234 8.75309V24.4282L1 24.4282L1 8.75309Z"
    stroke="#4488C8"
    stroke-width="2"
  />
  <path
    d="M8.62842 15.4094L18.4949 15.4094L18.4949 24.4283H8.62842L8.62842 15.4094Z"
    stroke="#4488C8"
    stroke-width="2"
  />
</template>
