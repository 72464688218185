import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

function checkPropsAlert(candidate) {
  if (typeof candidate !== 'object') {
    throw new Error('Некорректный тип данных, ожидается объект')
  }

  if (!candidate.type) {
    throw new Error('Не указан тип сообщения')
  }
  if (!candidate.text) {
    throw new Error('Не указано сообщение')
  }

  return true
}

export const useAlertStore = defineStore('alert', () => {
  const alert = reactive({
    type: '',
    text: ''
  })
  const isShow = ref(false)

  function setAlert(newAlert) {
    if (checkPropsAlert(newAlert)) {
      Object.keys(alert).forEach((key) => (alert[key] = newAlert[key]))
      isShow.value = true

      setTimeout(() => {
        isShow.value = false
      }, 10000)
    }
  }

  function closeAlert() {
    isShow.value = false
  }

  return { alert, setAlert, isShow, closeAlert }
})
