import { userRequest } from '@/http/UserRequest'
import { useUserStore } from '@/store/user/useUserStore'
import { LocalStorage } from '@/extension/storage/LocalStorage'

class AuthService {
  constructor() {
    this.userScopes = []
  }

  fetchUser = async () => {
    if (!LocalStorage.getItem('token')) return
    const response = await userRequest.getUserByToken()
    if (!response) return
    const user = useUserStore()
    user.setPersonal(response.data)
    this.userScopes = response.data.permissions
  }

  checkHasScope = (scopes) => {
    if (!scopes) return false
    return this.userScopes.some((scope) => scopes.includes(scope))
  }
}

let singletonService = null

function useService() {
  if (!singletonService) singletonService = new AuthService()
  return singletonService
}

export { useService as useAuthService }
