<template>
  <!--  width="17" height="19"-->
  <mask id='path-1-inside-1_14_2707' fill='white'>
    <rect y='2.58881' width='17' height='15.8667' rx='1' />
  </mask>
  <rect y='2.58881' width='17' height='15.8667' rx='1' stroke='#069EE0' stroke-width='3'
        mask='url(#path-1-inside-1_14_2707)' />
  <rect y='3.32214' width='16' height='4' fill='#069EE0' />
  <rect x='9' y='10.3221' width='5' height='5' fill='#069EE0' />
  <rect x='4.5332' y='0.322144' width='3.4' height='2.26667' rx='1' transform='rotate(90 4.5332 0.322144)'
        fill='#069EE0' />
  <rect x='14.7332' y='0.322144' width='3.4' height='2.26667' rx='1' transform='rotate(90 14.7332 0.322144)'
        fill='#069EE0' />
</template>
