<template>
  <router-link :to="{ path: '/' }" class='max-h'>
    <img :src="require('@/assets/icons/logo.png')" alt='logo' />
  </router-link>
</template>

<style scoped>
  .max-h {
    max-height: 40px;
  }
</style>
