<template>
  <v-app>
    <v-main class='bg'>
      <transition name='slide-fade'>
        <v-alert-custom
          v-if='checkNewAlert'
          :type='getAlertConfigurate.type'
          :text='getAlertConfigurate.text'
          @click='alert.closeAlert'
        />
      </transition>
      <component :is='currentLayout'></component>
    </v-main>
  </v-app>
</template>

<script>
  import { useLayout } from '@/modules/layouts/useLayout'
  import AuthLayout from '@/layouts/AuthLayout'
  import DefaultLayout from '@/layouts/DefaultLayout'
  import VAlertCustom from '@/components/ui/alert/VAlertCustom'
  import { manualService } from '@/http/services/ManualService'
  import { useAlertStore } from '@/store/alert/useAlertStore'
  import { computed, onBeforeMount, watch } from 'vue'
  import { useUserStore } from '@/store/user/useUserStore'

  export default {
    setup() {
      const alert = useAlertStore()

      const checkNewAlert = computed(() => alert.isShow)
      const getAlertConfigurate = computed(() => alert.alert)

      const user = useUserStore()

      const getIsAuth = computed(() => user.isAuth)

      watch(getIsAuth, async () => {
        if (user.isAuth) {
          await manualService.allManualInitial(user)
        }
      })

      onBeforeMount(() => {
        document.title = 'ММК-Метиз'
      })

      return {
        ...useLayout(),
        alert,
        checkNewAlert,
        getAlertConfigurate
      }
    },
    components: {
      VAlertCustom,
      AuthLayout,
      DefaultLayout
    }
  }
</script>

<style>
  .bg {
    background-color: var(--color-gray-1);
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

  .size__img {
    width: 80vw !important;
  }

  .size__img img {
    height: 75vh;
  }
</style>
