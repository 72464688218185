<template>
  <!-- "35" height="32" -->
  <rect width='35' height='32' fill='white' />
  <path
    d='M23.5001 2H4.00012C3.44784 2 3.00012 2.44771 3.00012 3V29C3.00012 29.5523 3.44784 30 4.00012 30H29.0001C29.5524 30 30.0001 29.5523 30.0001 29V18.5'
    stroke='#95A4C0' stroke-width='2' stroke-linecap='round' />
  <path d='M24.2137 20.5582L19.3656 22.9554L19.0176 17.5582L28.3662 1.36597L33.5623 4.36597L24.2137 20.5582Z'
        stroke='#95A4C0' stroke-width='2' />
  <rect x='7.50012' y='7.5' width='9' height='1' rx='0.5' stroke='#95A4C0' />
  <rect x='7.50012' y='13.5' width='6' height='1' rx='0.5' stroke='#95A4C0' />
</template>
