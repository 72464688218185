<template>
  <!-- w-28 h-20 -->
  <path
    d="M0.999756 12.0188L6.62822 12.0188V18.4949H0.999756L0.999756 12.0188Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M11.1711 0.999939L26.1233 0.999939L26.1233 7.47601H11.1711L11.1711 0.999939Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M11.1711 12.0188L26.1233 12.0188L26.1233 18.4949H11.1711L11.1711 12.0188Z"
    stroke="white"
    stroke-width="2"
  />
</template>
