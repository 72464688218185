export default {
  DARK: '#172437',
  BLUE: '#1C498E',
  LIGHT_BLUE: '#069EE0',
  MEDIUM_BLUE: '#4488C8',
  LIGHT: '#D0E8FD',
  RED: '#F80D0D',
  ORANGE: '#F06D2F',
  GREEN: '#2AAB6D',
  WHITE: '#FFFFFF',

  GRAY_1: '#FAFAFA',
  GRAY_2: '#F1F5F9',
  GRAY_3: '#D6D6D6',
  GRAY_4: '#ABABAB'
}
