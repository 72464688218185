import { manualRequest } from '@/http/ManualRequest'
import { useManualStore } from '@/store/manual/useManualStore'
import { Alert } from '@/extension/Alert'
import { manualName } from '@/utils/storage/manualNames'
import { useAuthService } from '@/modules/auth/auth.service'
import { accessScopes } from '@/modules/auth/access.scopes'

export class ManualService {
  constructor() {
    this.alert = Alert()
    this.adminManual = [
      {
        key: manualName.ZONES,
        permissions: accessScopes.ZONE_INDEX
      },
      {
        key: manualName.ROLES,
        permissions: accessScopes.ZONE_INDEX
      }
    ]
  }

  async allManualInitial() {
    try {
      const manual = useManualStore()
      const keysManual = Object.keys(manual.list).filter(
        (key) => Reflect.has(manualRequest, key) === true
      )
      const { checkHasScope } = useAuthService()

      for (let i = 0; i < keysManual.length; i++) {
        const key = keysManual[i]
        const adminRoute = this.adminManual.find((man) => man.key === key)
        if (adminRoute && !checkHasScope([adminRoute.permissions])) return

        const { data } = await manualRequest[key]()
        manual.setList(key, data)
      }
    } catch (e) {
      this.alert.setError(e)
    }
  }
}

const manual = new ManualService()

export { manual as manualService }
