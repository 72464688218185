import { RequestHttp } from '@/http/RequestHttp'
import { manualName } from '@/utils/storage/manualNames'

export class ManualRequest extends RequestHttp {
  async [manualName.CAMERAS](query = '') {
    if (!query) {
      query = '?per-page=100'
    }
    const { data } = await this.$authHost.get(`/cameras/${query}`)
    return data.data
  }

  async [manualName.USERS](query = '') {
    const { data } = await this.$authHost.get(`/users/${query}`)
    return data.data
  }

  async [manualName.ZONES](query = '') {
    const { data } = await this.$authHost.get(`/zones/${query}`)
    return data.data
  }

  async [manualName.ROLES]() {
    const { data } = await this.$authHost.get('/roles')
    return data
  }

  async [manualName.REPORTS]() {
    const { data } = await this.$authHost.get('/reports/types')
    return data
  }
}

const manual = new ManualRequest()

export { manual as manualRequest }
