<template>
  <!-- w-36 h-36 -->
  <path d='M18 24L18 18' stroke='#4488C8' stroke-width='1.5' stroke-linecap='round' />
  <path fill-rule='evenodd' clip-rule='evenodd'
        d='M17.3161 12.7447C16.9058 12.375 16.8728 11.7427 17.2424 11.3324L17.257 11.3162C17.6267 10.9059 18.259 10.8729 18.6693 11.2425C19.0796 11.6122 19.1126 12.2445 18.743 12.6548L18.7284 12.671C18.3587 13.0814 17.7264 13.1143 17.3161 12.7447Z'
        fill='#4488C8' />
  <path
    d='M3.75 18C3.75 10.1299 10.1299 3.75 18 3.75C25.8701 3.75 32.25 10.1299 32.25 18C32.25 25.8701 25.8701 32.25 18 32.25C10.1299 32.25 3.75 25.8701 3.75 18Z'
    stroke='#4488C8' stroke-width='1.5' />
</template>
