import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { decomposeObject } from '@/utils/helper.function'

export const useUserStore = defineStore('user', () => {
  const personal = reactive({
    job: '',
    name: ''
  })
  const isAuth = ref(false)

  function setPersonal(info) {
    decomposeObject(personal, info)
    isAuth.value = true
  }

  function clearPersonal() {
    Object.keys(personal).forEach((key) => (personal[key] = null))
    isAuth.value = false
  }

  const getIsAuth = computed(() => isAuth.value)

  return { personal, setPersonal, clearPersonal, isAuth, getIsAuth }
})
