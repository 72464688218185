import { defineStore } from 'pinia'
import { manualName } from '@/utils/storage/manualNames'
import { reactive } from 'vue'

export const useManualStore = defineStore('manual', () => {
  const list = reactive({
    [manualName.CAMERAS]: reactive([]),
    [manualName.NAME_ERRORS]: [
      { title: 'Опасная зона', value: 0 },
      { title: 'Распознование СИЗ', value: 1 }
    ],
    [manualName.ZONES]: reactive([]),
    [manualName.USERS]: reactive([]),
    [manualName.ROLES]: reactive([]),
    [manualName.REPORTS]: reactive([])
  })

  function setList(key, val) {
    list[key].length = 0
    if (!val?.length) return
    list[key] = reactive(val)
  }

  return { list, setList }
})
