export class Storage {
  constructor() {
    this.warnMessage = 'Method has not is implemented'
  }

  getItem() {
    console.warn(this.warnMessage)
  }

  getAllItems() {
    console.warn(this.warnMessage)
  }

  setItem() {
    console.warn(this.warnMessage)
  }

  clearItem() {
    console.warn(this.warnMessage)
  }
}
