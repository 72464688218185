<script setup>
  import VCustomIcon from '@/modules/customIcon/VCustomIcon'
  import { useUserStore } from '@/store/user/useUserStore'
  import { authService } from '@/http/services/AuthService'
  import VAvatarCustom from '@/components/VAvatarCustom'
  import { computed } from 'vue'

  const user = useUserStore()

  const getUser = computed(() => {
    return user.personal
  })
</script>

<template>
  <div class='personal' v-if='getUser?.id'>
    <v-menu transition='slide-y-transition' class='personal__menu'>
      <template v-slot:activator='{ props }'>
        <div class='personal__inner cursor' v-bind='props'>
          <v-avatar-custom :get-user='getUser' />

          <v-custom-icon width='12' height='12' icon-name='Arrow' />
        </div>
      </template>
      <v-list>
        <v-list-item class='btn--personal-out'>
          <v-list-item-title class='cursor personal-window'>
            <v-custom-icon icon-name='OutDoor' width='26' height='25' />
            <p @click='authService.logout'>Выйти из аккаунта</p>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style>
  .personal {
    margin-right: 30px;
  }

  .personal__inner {
    display: flex;
    align-items: center;
  }

  .personal__info {
    margin: 0 10px;
    font-size: var(--font-size-primary);
    color: var(--color-dark);
    font-weight: 600;
  }

  .personal__job {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-medium-blue);
  }

  .personal-window {
    display: flex;
    align-items: center;
  }

  .personal-window svg {
    margin-right: 10px;
  }

  .btn--personal-out {
    padding: 24px 0;

    transition-duration: 300ms;
  }

  .btn--personal-out:hover {
    background-color: var(--color-gray-2);
  }

</style>

<style>
  .btn--personal-out path {
    transition-duration: 300ms;
  }

  .btn--personal-out:hover path:first-child {
    stroke: var(--color-light-blue);
  }

  .btn--personal-out:hover path:last-child {
    fill: var(--color-light-blue);
  }

  .personal__menu .v-overlay__content {
    top: 135px !important;
    box-shadow: none;
  }

  .personal__menu .v-overlay__content .v-list {
    background: #ffffff;
    box-shadow: 0 7px 11px #d0e8fd;
  }
</style>
