<template>
  <!-- w-32 h-31 -->
  <path d='M14 12.875H7' stroke='white' stroke-width='2' stroke-linecap='round' />
  <path d='M14 16.5H7' stroke='white' stroke-width='2' stroke-linecap='round' />
  <path
    d='M7 25.5625L9.32733 23.1018C9.70113 22.7066 10.3223 22.6827 10.7254 23.048L12.7644 24.8958C13.1715 25.2648 13.8001 25.2362 14.172 24.8317L18.5 20.125M15 20.125L18.5 20.125M18.5 20.125L19 23.75'
    stroke='white' stroke-width='2' stroke-linecap='round' />
  <mask id='path-4-inside-1_436_124' fill='white'>
    <rect x='1' y='6.53125' width='23' height='24.4688' rx='1' />
  </mask>
  <rect x='1' y='6.53125' width='23' height='24.4688' rx='1' stroke='white' stroke-width='4'
        mask='url(#path-4-inside-1_436_124)' />
  <path
    d='M6 7.4375V3C6 2.44772 6.44772 2 7 2H28C28.5523 2 29 2.44772 29 3V25.4687C29 26.021 28.5523 26.4688 28 26.4688H23'
    stroke='white' stroke-width='2' />
</template>
