import { useAuthService } from '@/modules/auth/auth.service'

export const authDirectives = {
  async mounted(el, binding, node) {
    const { checkHasScope } = useAuthService()
    const result = checkHasScope(binding.value)
    if (!result) {
      node.el.parentElement.removeChild(el)
    }
    return result
  }
}
