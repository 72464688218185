// Styles
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import 'vuetify/styles'
import * as labs from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'

// Vuetify
import { createVuetify } from 'vuetify'
import { themes } from '@/modules/vuetify/Themes'
// import { icon } from '@/modules/vuetify/Icon'

const configuration = {
  theme: {
    ...themes
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
    // ...icon
  },
  components: {
    ...labs
  },
  directives
}

export default createVuetify(configuration)
// Первая ссылка на рабочую док-цию, вторая на старую.
// https://dev.vuetifyjs.com/en
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
