import { accessScopes } from '@/modules/auth/access.scopes'
import { routesNameList } from '@/router/routesNameList'
import { registryTablesName } from '@/core/registryTablesName'

export default [
  {
    path: '/registry',
    name: routesNameList.REGISTRY,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.INDEX],
      table: registryTablesName.DEFAULT,
      isFilter: true,
      isXslx: true
    }
  },
  {
    path: '/registry-video',
    name: routesNameList.REGISTRY_VIDEO,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.INDEX],
      table: registryTablesName.VIDEO,
      isFilter: true,
      isXslx: true
    }
  },
  {
    path: '/video/:id(\\d+)',
    name: routesNameList.DETAIL_EVENT,
    component: () =>
      import(/* webpackChunkName: "DetailCamera" */ '@/views/DetailCamera'),
    meta: {
      accessScopes: [accessScopes.SHOW]
    }
  },
  {
    path: '/registry-error',
    name: routesNameList.REGISTRY_ERROR,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.INDEX],
      table: registryTablesName.ERROR,
      isFilter: true,
      isXslx: true
    }
  },
  {
    path: '/registry-checked',
    name: routesNameList.REGISTRY_CHECKED,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.INDEX],
      table: registryTablesName.CHECKED,
      isFilter: true,
      isXslx: true
    }
  },
  {
    path: '/manual',
    name: routesNameList.MANUAL,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.ACTIVITY_INDEX],
      table: registryTablesName.MANUAL,
      isSearch: true,
      isCreate: true
    }
  },
  {
    path: '/journal',
    name: routesNameList.JOURNAL,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      accessScopes: [accessScopes.ZONE_INDEX, accessScopes.USER_INDEX],
      table: registryTablesName.JOURNAL,
      isSearch: true
    }
  },
  {
    path: '/report',
    name: routesNameList.REPORT,
    component: () =>
      import(/* webpackChunkName: "RegistryView" */ '@/views/RegistryView'),
    meta: {
      table: registryTablesName.REPORT,
      isSearch: false,
      isFilter: true,
      isReport: true,
      isXslx: true,
      accessScopes: [accessScopes.REPORT]
    }
  }
]
