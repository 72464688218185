<script setup>
  import { computed, defineProps } from 'vue'
  import VCustomIcon from '@/modules/customIcon/VCustomIcon'

  const props = defineProps({
    icon: {
      type: Object,
      required: false
    },
    to: {
      type: [Object, String],
      required: false
    },
    classText: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  const getIcon = computed(() => props.icon)
</script>

<template>
  <router-link
    :to='to'
    v-if='!isLink'
  >
    <v-custom-icon
      v-if='getIcon'
      :icon-name='getIcon.name'
      :width='getIcon.width'
      :height='getIcon.height'
    />
    <p :class='classText'>
      <slot />
    </p>
  </router-link>

  <a
    v-else
    :href='to'
    target='_blank'
    :class='{ disabled: isDisabled }'
  >
    <v-custom-icon
      v-if='getIcon'
      :icon-name='getIcon.name'
      :width='getIcon.width'
      :height='getIcon.height'
    />
    <p :class='classText'>
      <slot />
    </p>
  </a>
</template>

<style>
  .disabled {
    pointer-events: none;
    opacity: 0;
  }
</style>
