<template>
  <!-- w-23  h-21 -->
  <mask id="path-1-inside-1_217_360" fill="white">
    <rect y="3.05176e-05" width="22.6028" height="20.3426" rx="1" />
  </mask>
  <rect
    y="3.05176e-05"
    width="22.6028"
    height="20.3426"
    rx="1"
    stroke="white"
    stroke-width="4"
    mask="url(#path-1-inside-1_217_360)"
  />
  <path
    d="M16.9521 10.5951L8.47608 14.4093L8.47608 6.78085L16.9521 10.5951Z"
    fill="white"
  />
</template>
