<template>
  <!-- width="33" height="32" -->
  <path
    d="M4.5 1.00012L4.5 30.1668"
    stroke="#069EE0"
    stroke-width="2"
    stroke-linecap="round"
  />
  <ellipse
    cx="4.5"
    cy="9.16675"
    rx="3.5"
    ry="3.5"
    transform="rotate(90 4.5 9.16675)"
    fill="#FAFAFA"
    stroke="#069EE0"
    stroke-width="2"
  />
  <path
    d="M28.5 1.00012L28.5 30.1668"
    stroke="#069EE0"
    stroke-width="2"
    stroke-linecap="round"
  />
  <ellipse
    cx="28.5"
    cy="9.16675"
    rx="3.5"
    ry="3.5"
    transform="rotate(90 28.5 9.16675)"
    fill="#FAFAFA"
    stroke="#069EE0"
    stroke-width="2"
  />
  <path
    d="M16.5 30.1669L16.5 1.0002"
    stroke="#069EE0"
    stroke-width="2"
    stroke-linecap="round"
  />
  <ellipse
    cx="3.5"
    cy="3.5"
    rx="3.5"
    ry="3.5"
    transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 20 25.5002)"
    fill="#FAFAFA"
    stroke="#069EE0"
    stroke-width="2"
  />
</template>
