<template>
  <!-- w-32  h-31 -->
  <path
    d='M2.75 7C2.75 6.86193 2.86193 6.75 3 6.75H23C23.1381 6.75 23.25 6.86193 23.25 7V24C23.25 24.1381 23.1381 24.25 23 24.25H3C2.86193 24.25 2.75 24.1381 2.75 24V7Z'
    stroke='#95A4C0' stroke-width='1.5' />
  <circle cx='7.5' cy='19.5' r='1.5' fill='#95A4C0' />
  <path
    d='M23.75 12.8089C23.75 12.7308 23.7866 12.6571 23.8489 12.6098L28.8489 8.81516C29.0135 8.69022 29.25 8.80764 29.25 9.01431V22.106C29.25 22.3069 29.0249 22.4257 28.859 22.3124L23.859 18.8972L23.436 19.5165L23.859 18.8972C23.7908 18.8506 23.75 18.7734 23.75 18.6908V12.8089Z'
    stroke='#95A4C0' stroke-width='1.5' />
</template>
