<template>
  <!-- width="31" height="31" -->
  <rect
    x="0.446429"
    y="0.446429"
    width="30.1071"
    height="30.1071"
    rx="1.33929"
    stroke="#95A4C0"
    stroke-width="0.892857"
  />
  <path
    d="M12.3634 17.8997L5.16699 25.6497M5.16699 25.6497V20.1139M5.16699 25.6497H10.7027"
    stroke="#95A4C0"
    stroke-width="0.892857"
    stroke-linecap="round"
  />
  <path
    d="M18.9545 18.2683L26.1509 26.0183M26.1509 26.0183V20.4826M26.1509 26.0183H20.6152"
    stroke="#95A4C0"
    stroke-width="0.892857"
    stroke-linecap="round"
  />
  <path
    d="M12.3634 12.9167L5.16699 5.16675M5.16699 5.16675V10.7025M5.16699 5.16675H10.7027"
    stroke="#95A4C0"
    stroke-width="0.892857"
    stroke-linecap="round"
  />
  <path
    d="M18.9545 13.2853L26.1509 5.53528M26.1509 5.53528V11.071M26.1509 5.53528H20.6152"
    stroke="#95A4C0"
    stroke-width="0.892857"
    stroke-linecap="round"
  />
</template>
