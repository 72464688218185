<template>
  <!-- width="20" height="24" -->
  <path
    d='M2.20825 5.78736C2.19482 5.64089 2.31012 5.51453 2.45721 5.51453H17.9623C18.1094 5.51453 18.2247 5.64088 18.2113 5.78736L16.7093 22.1649C16.6975 22.2936 16.5896 22.3921 16.4603 22.3921H3.95921C3.82999 22.3921 3.72206 22.2936 3.71025 22.1649L2.20825 5.78736Z'
    stroke='#95A4C0' stroke-width='1.5' />
  <path
    d='M17.8477 2.82759C17.9993 2.98675 17.8865 3.25 17.6667 3.25H2.33333C2.11353 3.25 2.00071 2.98675 2.1523 2.82759L1.62604 2.32639L2.1523 2.82758C2.19948 2.77804 2.26491 2.75 2.33333 2.75H17.6667C17.7351 2.75 17.8005 2.77804 17.8477 2.82759Z'
    stroke='#95A4C0' stroke-width='1.5' />
  <path
    d='M12.0156 2.36554C12.0387 2.51687 11.9215 2.6532 11.7684 2.6532H8.65109C8.49801 2.6532 8.38088 2.51687 8.40394 2.36554L8.61777 0.96228C8.63638 0.840162 8.74139 0.749941 8.86492 0.749941H11.5546C11.6781 0.749941 11.7832 0.840162 11.8018 0.962281L12.0156 2.36554Z'
    stroke='#95A4C0' stroke-width='1.5' />
  <path d='M10.2098 8.84851L10.2098 19.0583' stroke='#95A4C0' stroke-width='1.5' stroke-linecap='round' />
  <path d='M6.12586 8.84851L6.80651 19.0583' stroke='#95A4C0' stroke-width='1.5' stroke-linecap='round' />
  <path d='M14.2937 8.84851L13.613 19.0583' stroke='#95A4C0' stroke-width='1.5' stroke-linecap='round' />
</template>
