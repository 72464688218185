<template>
  <!-- w-36 h-36 -->
  <path d='M18 12L18 18' stroke='#D21E1E' stroke-width='1.5' stroke-linecap='round' />
  <path fill-rule='evenodd' clip-rule='evenodd'
        d='M17.3161 23.2553C16.9058 23.625 16.8728 24.2573 17.2424 24.6676L17.257 24.6838C17.6267 25.0941 18.259 25.1271 18.6693 24.7575C19.0796 24.3878 19.1126 23.7555 18.743 23.3452L18.7284 23.329C18.3587 22.9186 17.7264 22.8857 17.3161 23.2553Z'
        fill='#D21E1E' />
  <path
    d='M3.75 18C3.75 25.8701 10.1299 32.25 18 32.25C25.8701 32.25 32.25 25.8701 32.25 18C32.25 10.1299 25.8701 3.75 18 3.75C10.1299 3.75 3.75 10.1299 3.75 18Z'
    stroke='#D21E1E' stroke-width='1.5' />
</template>
