import { useUserStore } from '@/store/user/useUserStore'
import { authRequest } from '@/http/AuthRequest'
import router from '@/router'
import { routesNameList } from '@/router/routesNameList'
import { LocalStorage } from '@/extension/storage/LocalStorage'
import { Alert } from '@/extension/Alert'

class AuthService {
  constructor() {
    this.alert = Alert()
  }

  async logout() {
    try {
      await authRequest.logout()
      LocalStorage.clearItem('token')
      const user = useUserStore()
      user.clearPersonal()
      await router.push({ name: routesNameList.AUTH })
    } catch (e) {
      this.alert.setError(e)
    }
  }

  async login(formData) {
    try {
      const user = useUserStore()
      const { data } = await authRequest.login(formData)
      const name = data.user.firstname + ' ' + data.user.lastname
      user.setPersonal({
        job: data.user.roles[0].title,
        name: name
      })
      LocalStorage.setItem('token', data.token)

      await router.push({ name: routesNameList.REGISTRY })
    } catch (e) {
      this.alert.setError(e)
    }
  }

  async registration(formData) {
    try {
      await authRequest.registration(formData)
      this.alert.setSuccess('Пользователь успешно создан')
    } catch (e) {
      this.alert.setError(e)
    }
  }

  async update(formData) {
    try {
      await authRequest.update(formData, formData.id)
      this.alert.setSuccess('Пользователь успешно изменён')
    } catch (e) {
      this.alert.setError(e)
    }
  }
}

const auth = new AuthService()

export { auth as authService }
