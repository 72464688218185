import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/router/auth/auth'
import panel from './panel/panel'
import camera from './camera/camera'
import LayoutStorage from '@/modules/layouts/layoutStorage'
import { pinRouteLayout } from '@/utils/helper.function'
import { fetchAuthDataMiddleware } from '@/router/middleware/fetchAuthData.middleware'
import { accessGuardMiddleware } from '@/router/middleware/accessGuard.middleware'
import { routesNameList } from '@/router/routesNameList'
import { clearFilterMiddleware } from '@/router/middleware/clearFilter.middleware'

const routes = [
  {
    path: '/',
    redirect: routesNameList.REGISTRY
  },
  {
    path: '/:notFound(.*)',
    redirect: { name: routesNameList.AUTH }
  }
]

routes.push(...pinRouteLayout(auth, LayoutStorage.AUTH))
routes.push(...pinRouteLayout(panel, LayoutStorage.DEFAULT))
routes.push(...pinRouteLayout(camera, LayoutStorage.DEFAULT))

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(fetchAuthDataMiddleware)
router.beforeEach(accessGuardMiddleware)
router.beforeEach(clearFilterMiddleware)

export default router
