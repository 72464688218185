<template>
  <path
    d="M14.6296 6.75L14.6296 2C14.6296 1.44772 14.1819 1 13.6296 1L2.00001 0.999999C1.44772 0.999999 1.00001 1.44771 1.00001 2L1.00001 23C1.00001 23.5523 1.44772 24 2.00001 24L13.6296 24C14.1819 24 14.6296 23.5523 14.6296 23L14.6296 18.25"
    stroke="#1C498E"
    stroke-width="2"
    stroke-linecap="round"
  />
  <path
    d="M24 12.4465L24.7704 11.8089L25.2931 12.4404L24.7764 13.0768L24 12.4465ZM20.2374 18.6674C19.8893 19.0961 19.2595 19.1615 18.8308 18.8134C18.402 18.4653 18.3366 17.8355 18.6847 17.4067L20.2374 18.6674ZM18.6907 7.60061C18.3386 7.17517 18.398 6.5448 18.8234 6.19264C19.2489 5.84048 19.8792 5.89989 20.2314 6.32533L18.6907 7.60061ZM9.51855 11.4465L24 11.4465L24 13.4465L9.51855 13.4465L9.51855 11.4465ZM24.7764 13.0768L20.2374 18.6674L18.6847 17.4067L23.2237 11.8162L24.7764 13.0768ZM23.2297 13.0842L18.6907 7.60061L20.2314 6.32533L24.7704 11.8089L23.2297 13.0842Z"
    fill="#1C498E"
  />
</template>
