<template>
  <!-- w-28 h-20 -->
  <path
    d="M1 12.0189L6.62846 12.0189V18.4949H1L1 12.0189Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M11.1714 1L26.1235 1L26.1235 7.47607H11.1714L11.1714 1Z"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M11.1714 12.0189L26.1235 12.0189L26.1235 18.4949H11.1714L11.1714 12.0189Z"
    stroke="white"
    stroke-width="2"
  />
  <path d="M3.39038 0.423889L3.39038 4.91122" stroke="white" stroke-width="2" />
  <path d="M3.39038 6.25745L3.39038 8.05238" stroke="white" stroke-width="2" />
</template>
