import { useAuthService } from '@/modules/auth/auth.service'
import { routesNameList } from '@/router/routesNameList'

export function accessGuardMiddleware(to) {
  const { accessScopes } = to.meta

  if (!accessScopes) return
  const { checkHasScope } = useAuthService()
  if (checkHasScope(accessScopes)) return

  return { name: routesNameList.AUTH }
}
