<template>
  <circle cx="26.5" cy="26.5" r="26.5" fill="#F1F5F9" />
  <circle cx="27" cy="19" r="7" stroke="#1C498E" stroke-width="2" />
  <path
    d="M16 39V37.5C16 31.701 20.701 27 26.5 27V27C32.299 27 37 31.701 37 37.5V39"
    stroke="#1C498E"
    stroke-width="2"
    stroke-linecap="round"
  />
</template>
