<template>
  <!-- width="23" height="22" -->
  <path
    d='M14.9534 1.36133H2C1.44772 1.36133 1 1.80904 1 2.36133V19.4196C1 19.9719 1.44772 20.4196 2 20.4196H18.3776C18.9299 20.4196 19.3776 19.9719 19.3776 19.4196V12.5921'
    stroke='#95A4C0' stroke-width='1.5' stroke-linecap='round' />
  <path
    d='M15.4273 13.8746C15.4027 13.9173 15.3659 13.9518 15.3216 13.9737L12.5379 15.3501C12.378 15.4292 12.1891 15.3201 12.1776 15.1421L11.9777 12.0431C11.9745 11.9938 11.986 11.9447 12.0107 11.902L18.1658 1.24101C18.2349 1.12143 18.3878 1.08047 18.5073 1.1495L21.491 2.87211C21.6105 2.94114 21.6515 3.09404 21.5825 3.21361L15.4273 13.8746Z'
    stroke='#95A4C0' stroke-width='1.5' />
  <rect x='4.06293' y='5.10497' width='6.12586' height='0.680651' rx='0.340326' stroke='#95A4C0'
        stroke-width='0.680651' />
  <rect x='4.06293' y='9.18884' width='4.08391' height='0.680651' rx='0.340326' stroke='#95A4C0'
        stroke-width='0.680651' />
</template>
