export const registryTablesName = {
  DEFAULT: 'Default',
  VIDEO: 'Video',
  ERROR: 'Error',
  CHECKED: 'Checked',
  JOURNAL: 'Journal',
  MANUAL: 'Manual',
  MANUAL_CAMERA: 'ManualCamera',
  MANUAL_ZONES: 'ManualZones',
  REPORT: 'Report'
}
