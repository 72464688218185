export const routesCameraList = {
  CURRENT_CAMERA: 'currentCamera'
}

export const routesNameList = {
  AUTH: 'auth',
  RESET: 'reset',
  REGISTRY: 'registry',
  REGISTRY_VIDEO: 'registry-video',
  REGISTRY_ERROR: 'registry-error',
  REGISTRY_CHECKED: 'registry-checked',
  MANUAL: 'manual',
  JOURNAL: 'journal',
  DETAIL_EVENT: 'detailEvent',
  REPORT: 'report',
  ...routesCameraList
}
