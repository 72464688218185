import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

export const useTableStore = defineStore('table', () => {
  const DEFAULT_PER_PAGE = 8

  const filter = reactive({
    Processed: false,
    Type: null,
    Approved: null,
    camera_id: null,
    range: reactive({
      start: '',
      end: ''
    })
  })

  const query = ref('')

  const perPage = ref(DEFAULT_PER_PAGE)
  const sort = ref('-start_time')
  const page = ref(1)
  const search = ref('')

  function clearFilter() {
    Object.keys(filter).forEach((key) => {
      if (key !== 'range' && key !== 'Processed') {
        filter[key] = null
      }
    })
  }

  function clear() {
    sort.value = '-start_time'
    page.value = 1
    search.value = ''
    clearFilter()
  }

  function setFilter(obj) {
    Object.keys(obj).forEach((key) => (filter[key] = obj[key]))
  }

  function setQuery(str) {
    query.value = str
  }

  function setFilterKey(key, val) {
    filter[key] = val
  }

  function setSort(str = '') {
    console.log('setSort')
    sort.value = str
  }

  function setPage(num) {
    page.value = num
  }

  function setPerPage(num) {
    perPage.value = num
  }

  function setSearch(str = '') {
    search.value = str
  }

  return {
    setSearch,
    search,
    filter,
    setFilter,
    setQuery,
    query,
    clear,
    perPage,
    sort,
    setSort,
    page,
    setPage,
    setPerPage,
    setFilterKey
  }
})
