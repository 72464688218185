<script setup>
  import VCustomIcon from '@/modules/customIcon/VCustomIcon'
  import TextCorrecter from '@/extension/TextCorrecter'
  import { defineProps } from 'vue'

  defineProps({
    type: {
      type: String,
      validator(value) {
        return ['info', 'warn', 'success', 'error'].includes(value)
      }
    },
    text: {
      type: String,
      required: true
    }
  })

  const messages = {
    'warn': 'Внимание!',
    'error': 'Сообщение об ошибке',
    'success': 'Одобрение',
    'info': 'Информация'
  }
</script>


<template>
  <v-card class='alert box-shadow-clear'>
    <span :class='["alert-signal", type]'></span>
    <div class='alert__info'>
      <v-card-title :class='["mb-20 alert__title", type]'>
        <v-custom-icon :icon-name='TextCorrecter.capitalize(type) + "Alert"' width='36' height='36' />
        {{ messages[type] }}
      </v-card-title>
      <v-card-text class='alert__text'>
        {{ text }}
      </v-card-text>
    </div>
  </v-card>
</template>

<style scoped>
  .alert {
    position: absolute;
    right: 0;
    top: 120px;

    z-index: 99999;

    min-width: 565px;
    border-radius: 0;
    border: 1px solid var(--color-gray-3);

    padding: 25px 60px 25px 0;
  }

  .alert-signal {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    width: 8px;
  }

  .alert-signal.success {
    background-color: var(--color-green);
  }

  .alert-signal.error {
    background-color: var(--color-red);
  }

  .alert-signal.warn {
    background-color: var(--color-orange);
  }

  .alert-signal.info {
    background-color: var(--color-medium-blue);
  }

  .alert__info {
    padding-left: 20px;
  }

  .alert__title {
    padding: 0;
    font-weight: 600;
    font-size: 20px;

    display: flex;
    align-items: center;
  }

  .alert__title svg {
    margin-right: 15px;
  }

  .alert__title.success {
    color: var(--color-green);
  }

  .alert__title.warn {
    color: var(--color-orange);
  }

  .alert__title.error {
    color: var(--color-red);
  }

  .alert__title.info {
    color: var(--color-medium-blue);
  }

  .alert__text {
    color: var(--color-dark);
  }
</style>
