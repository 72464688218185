<template>
  <!-- width="25" height="25" -->
  <path
    d="M12.5 12.5L24 24M1 24L12.5 12.5L1 24ZM24 1L12.5 12.5L24 1ZM12.5 12.5L1 1L12.5 12.5Z"
    stroke="#939393"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
