<script setup>
  import VCustomIcon from '@/modules/customIcon/VCustomIcon'
  import { routesNameList } from '@/router/routesNameList'
  import { accessScopes } from '@/modules/auth/access.scopes'
  import { useAuthService } from '@/modules/auth/auth.service'
  import { computed, ref } from 'vue'
  import VMenuList from '@/components/list/VMenuList'
  import { useUserStore } from '@/store/user/useUserStore'

  const { checkHasScope } = useAuthService()
  const isOpen = ref(false)

  const user = useUserStore()
  const isDisabled = computed(() => {
    return !user.personal?.id
  })

  const items = [
    {
      name: 'Общий реестр',
      iconName: 'Home',
      routeName: { name: routesNameList.REGISTRY },
      scopes: [accessScopes.INDEX]
    },
    {
      name: 'Реестр необработанных нарушений',
      iconName: 'RepositoryError',
      routeName: { name: routesNameList.REGISTRY_ERROR },
      scopes: [accessScopes.INDEX]
    },
    {
      name: 'Реестр обработанных нарушений',
      iconName: 'RepositoryErrorChecked',
      routeName: { name: routesNameList.REGISTRY_CHECKED },
      scopes: [accessScopes.INDEX]
    },
    {
      name: 'Реестр видеозаписей',
      iconName: 'RepositoryVideo',
      routeName: { name: routesNameList.REGISTRY_VIDEO },
      scopes: [accessScopes.INDEX]
    },
    {
      name: 'Видеотрансляция',
      iconName: 'Video',
      routeName: process.env.VUE_APP_VIDEO_LINK,
      scopes: [accessScopes.CAMERA_INDEX],
      isLink: true
    },
    {
      name: 'Справочники',
      iconName: 'Manual',
      routeName: { name: routesNameList.MANUAL },
      scopes: [accessScopes.USER_INDEX, accessScopes.ZONE_INDEX]
    },
    {
      name: 'Журнал',
      iconName: 'Journal',
      routeName: { name: routesNameList.JOURNAL },
      scopes: [accessScopes.ACTIVITY_INDEX]
    },
    {
      name: 'Отчёты',
      iconName: 'Report',
      routeName: { name: routesNameList.REPORT },
      scopes: [accessScopes.REPORT]
    }
  ]

  const itemsToRender = computed(() => {
    return items.filter((item) => checkHasScope(item.scopes))
  })
</script>

<template>
  <v-menu transition='slide-x-reverse-transition' class='menu'>
    <template v-slot:activator='{ props }'>
      <v-btn height='auto' :disabled='isDisabled'>
        <v-custom-icon
          v-bind='props'
          class='menu-icon'
          icon-name='Menu'
          width='40'
          height='40'
          @click='isOpen = !isOpen'
        />
      </v-btn>
    </template>

    <v-menu-list :items='itemsToRender' />
  </v-menu>
</template>

<style>
  .menu-icon path {
    stroke: var(--color-dark);
  }

  .menu-icon.disabled path {
    stroke: var(--color-gray-4);
  }

  .menu .v-overlay__content {
    position: absolute;
    left: auto !important;
    right: 0;
    top: 125px !important;

    width: 360px;
    height: calc(100% - 125px);

    background: var(--color-medium-blue);
  }

  .menu .v-list svg path {
    stroke: white;
  }

  .menu .v-list-item {
    cursor: pointer;
  }

  .menu .v-list-item:hover path,
  .menu .v-list-item:last-child:hover rect,
  .menu .v-list-item:nth-child(5):hover rect {
    stroke: var(--color-medium-blue);
  }

  .menu .v-list-item:nth-child(5):hover path {
    fill: var(--color-medium-blue);
  }

  .menu__link {
    padding: 30px 20px;

    display: flex;
    align-items: center;

    text-decoration: none;
    color: var(--color-white);
    font-size: 16px;
  }

  .menu__link svg {
    margin-right: 15px;
  }

  .menu .v-list-item,
  .menu .v-list-item p {
    transition-duration: 300ms;
  }

  .menu .v-list-item:hover {
    color: var(--color-medium-blue);
    background-color: var(--color-light);
  }

  .menu .v-list-item:hover p {
    color: var(--color-medium-blue);
  }

  .menu .v-list {
    padding: 0;
  }
</style>
