<template>
  <!-- w-17 h-17 -->
  <path
    d="M13.3086 3.69165L3.69194 13.3083"
    stroke="#F06D2F"
    stroke-width="1.7"
    stroke-linecap="round"
  />
  <path
    d="M13.3086 13.3083L3.69194 3.6917"
    stroke="#F06D2F"
    stroke-width="1.7"
    stroke-linecap="round"
  />
</template>
